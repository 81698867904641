import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import Layout from "../components/layout";
import { focusState } from "../tokens/a11y";

export default function Template({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  const { partners = null } = frontmatter;
  const breadcrumbs = [
    { name: "Home", href: "/" },
    { name: frontmatter.title }
  ];
  return (
    <Layout
      breadcrumbs={breadcrumbs}
      pageTitle={frontmatter.title}
      preambleText={frontmatter.preambleText}
      metaDescription={frontmatter.metaDescription}
    >
      {partners &&
        partners.map(partner => (
          <PartnerItem key={partner.link}>
            {partner.image &&
              partner.image.childImageSharp &&
              partner.image.childImageSharp.fluid && (
                <ImageContainer>
                  <Image>
                    <Img fluid={partner.image.childImageSharp.fluid} />
                  </Image>
                </ImageContainer>
              )}
            <TextBlock>
              <Text>
                <Title>
                  <Link href={partner.link}>{partner.title}</Link>
                </Title>
                <p>{partner.text}</p>
              </Text>
            </TextBlock>
          </PartnerItem>
        ))}
      <Content dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  );
}

const PartnerItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  margin: -1rem;

  & + & {
    padding-top: 2rem;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 20%;

  min-width: 280px;
  padding: 1rem;
`;

const Image = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  height: 200px;
  padding: 1rem;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.palette.white};

  img {
    object-fit: contain !important;
  }
`;

const TextBlock = styled.div`
  flex-grow: 1;
  flex-basis: 60%;

  padding: 1rem;
`;

const Text = styled.div`
  max-width: 60ch;
`;

const Title = styled.h2`
  margin-top: 0;
`;

const Link = styled.a`
  display: inline-block;
  margin: -0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;

  &:focus {
    ${focusState};
  }
`;

const Content = styled.div`
  max-width: 60ch;
`;

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        preambleText
        metaDescription
        partners {
          title
          text
          link
          image {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
